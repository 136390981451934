<template>
  <div class="Box">
    <div class="top">
      <div class="bannerPosition">
        <img class="code" src="/activity/startPage/code.png" alt="">
        <div>
          <img @click="downLoadIos" src="/activity/startPage/iosButton.png" alt="">
          <img @click="downLoadAndroid" src="/activity/startPage/androidButton.png" alt="">
        </div>
      </div>
    </div>
    <!-- second -->
    <div class="second">
      <img class="secondTitImg" src="/activity/startPage/secondTitImg.png" alt="">
      <img class="secondBigImg" src="/activity/startPage/secondBigImg.png" alt="">
      <img class="secondTitImg2" src="/activity/startPage/secondTitImg2.png" alt="">
      <div class="swiperBox">
        <!-- Swiper -->
        <div class="swiper-container swiperOne">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="/activity/startPage/swiperLeft.png" alt="">
            </div>
            <div class="swiper-slide">
              <img src="/activity/startPage/swiperCenter.png" alt="">
            </div>
            <div class="swiper-slide">
              <img src="/activity/startPage/swiperRight.png" alt="">
            </div>
	        </div>
        </div>
        <img class="blue left" src="/activity/startPage/blue1.png" alt="">
        <img class="blue right" src="/activity/startPage/blue2.png" alt="">
      </div>
    </div>
    <!-- third -->
    <div class="third">
      <img src="/activity/startPage/thirdBanner.png" alt="">
      <img src="/activity/startPage/thirdImg.png" alt="">
    </div>
    <!-- fourth -->
    <div class="fourth">
      <!-- <img src="/activity/startPage/fourthImg.png" alt=""> -->
      <div class="model_text" style="margin-right:15px;">
        <h2>积分当<span>钱</span>花</h2>
        <p>无固定账户层级</p>
        <p>用户自己<span>攒积分</span>升级</p>
        <p>每升一级，<span>每手返赠越高</span></p>
        <p>越交易<span>越省钱</span></p>
      </div>
      <img src="/activity/startPage/fourthBanner.png" alt="">
    </div>
    <!-- fifth -->
    <div class="fifth">
      <img src="/activity/startPage/fifthBanner.png" alt="">
      <!-- <img src="/activity/startPage/fifthImg.png" alt=""> -->
      <div class="model_text" style="text-align:right;">
        <h2>美元赠金<span>额外兑换</span></h2>
        <p>当月交易越多，每手<span>返还越高</span></p>
        <p>美元赠金<span>不分账户等级</span></p>
        <p>只按<span>交易手数</span>计算</p>
      </div>
    </div>
    <!-- sixth -->
    <div class="sixth">
      <img class="sixthTit1" src="/activity/startPage/sixthTit1.png" alt="">
      <img class="sixthTit2" src="/activity/startPage/sixthTit2.png" alt="">
      <div class="sixthCenter">
        <img class="brown" src="/activity/startPage/brown1.png" alt="">
        <div class="bottomSwiper">
          <!-- swiperTwo -->
          <div class="swiper-container swiperTwo">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner.png" alt="">
              </div>
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner2.png" alt="">
              </div>
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner3.png" alt="">
              </div>
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner4.png" alt="">
              </div>
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner5.png" alt="">
              </div>
            </div>
            <!-- 如果需要按钮 -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
          <img class="iphoneImg" src="/activity/startPage/iphonexBackground.png" alt="">
        </div>
        <img class="brown" src="/activity/startPage/brown2.png" alt="">
      </div>
      <img class="sixthTit3" src="/activity/startPage/sixthTit3.png" alt="">
    </div>
    <!-- footer -->
    <div class="footer">
      <img class="footerTit" src="/activity/startPage/footerTit.png" alt="">
      <div class="sevenContent">
        <p>
          <img src="/activity/startPage/code.png" alt="">
          <span>手机扫码 &nbsp; 即刻下载</span>
        </p>
        <p>
          <img @click="downLoadIos" src="/activity/startPage/footerIos.png" alt="">
          <img @click="downLoadAndroid" src="/activity/startPage/footerAndroid.png" alt="">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'startPageWap',
  data(){
    return{

    }
  },
  methods:{
    downLoadIos(){
      window.location.href = 'https://apps.apple.com/cn/app/%E5%AE%89%E8%83%9C%E8%B4%A2%E5%AF%8C/id1602800589';
    },
    downLoadAndroid(){
      window.location.href = 'https://oss.0790jiaxiao.com/HS/software/hs_9999.apk';
    }
  },
  mounted(){
    new Swiper ('.swiperOne', {
      slidesPerView: 3,
      spaceBetween: 0,
      centeredSlides: true,
      grabCursor:true,
      loop: true,
      autoplay: 3000,
      // 用户操作之后是否停止自动轮播默认true
      autoplayDisableOnInteraction :false,
    });
    new Swiper ('.swiperTwo', {
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      grabCursor:true,
      loop: true,
      autoplay: 3000,
      // 用户操作之后是否停止自动轮播默认true
      autoplayDisableOnInteraction :false,
      prevButton:'.swiper-button-prev',
      nextButton:'.swiper-button-next',
    });
  }
}
</script>

<style lang="less" scoped>
  .Box{
    width: 100%;
    .top{
      width: 7.5rem;
      height: 12.44rem;
      background: url('/activity/startPage/banner.png') no-repeat;
      background-size: cover;
      position: relative;
      .bannerPosition{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
        .code{
          width: 2.1rem;
          height: auto;
          margin-right: 0.35rem;
        }
        div{
          height: 100%;
          display: flex;
          flex-direction: column;
          img{
            width: 3.44rem;
            margin: .1rem 0;
          }
        }
      }
    }
    .second{
      width: 7.5rem;
      height: 13.2rem;
      background: url('/activity/startPage/secondBanner.png') no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: 0.6rem 0;
      .secondTitImg{
        width: 6.58rem;
      }
      .secondBigImg{
        width: 6.36rem;
        margin: 0.4rem auto;
      }
      .secondTitImg2{
        width: 5.97rem;
      }
      .swiperBox{
        width: 80%;
        margin: 0 auto;
        position: relative;
        margin-top: 0.4rem;
        .secondTitle{
          display: block;
          margin: 0 auto;
        }
        .swiperOne{
          width: 100%;
          height: 100%;
          .swiper-slide {
            text-align: center;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            transition: 300ms;
            transform: scale(0.8);
            img{
              width: 2.60rem;
              height: auto;
              opacity: .6;
            }
          }
          .swiper-slide-active,.swiper-slide-duplicate-active{
            transform: scale(1);
            img{
              opacity: 1;
            }
          }
        }
        .blue{
          position: absolute;
          z-index: 2;
          width: 2.20rem;
        }
        .right{
          top: 50%;
          left: 10%;
          transform: translate(-50%,-50%);
        }
        .left{
          top: 50%;
          right: -25%;
          transform: translate(-50%,-50%);
        }
      }
    }
    .third{
      width: 7.5rem;
      height: 5rem;
      background: url('/activity/startPage/thirdBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      img:nth-child(1){
        width: 3.72rem;
      }
      img:nth-child(2){
        width: 3.17rem;
      }
    }
    .fourth{
      width: 7.5rem;
      height: 5rem;
      background: url('/activity/startPage/fourthBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      img:nth-child(1){
        width: 3.71rem;
      }
      img:nth-child(2){
        width: 3.05rem;
      }
    }
    .fifth{
      width: 7.5rem;
      height: 5rem;
      background: url('/activity/startPage/fifthBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      img:nth-child(1){
        width: 3.59rem;
      }
      img:nth-child(2){
        width: 3.49rem;
      }
    }
    .sixth{
      width: 7.50rem;
      height: 11.21rem;
      background: url('/activity/startPage/sixthBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 0.65rem 0 0.5rem;
      .sixthTit1{
        width: 4.91rem;
      }
      .sixthTit2{
        width: 5.71rem;
        margin-top: 0.2rem;
      }
      .sixthTit3{
        width: 6.90rem;
      }
      .sixthCenter{
        width: 7.5rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 0.3rem;
        .brown{
          width: 1.9rem;
          height: 3.17rem;
        }
        .bottomSwiper{
          width: 3.50rem;
          position: relative;
          .swiperTwo{
            width: 3.50rem;
            height: 6.7rem;
            .swiper-slide{
              width: 3.50rem;
              text-align: center;
              img{
                width: 3.23rem;
                height: auto;
              }
            }
            .swiper-button-next{
              width: 0.64rem;
              height: 0.64rem;
              background: url('/activity/startPage/arrowRight.png');
              background-size: cover;
              top: auto;
              bottom: -.1rem;
              right: 0.7rem;
            }
            .swiper-button-prev{
              width: 0.64rem;
              height: 0.64rem;
              background: url('/activity/startPage/arrowLeft.png');
              background-size: cover;
              top: auto;
              bottom: -.1rem;
              left: 0.7rem;
            }
          }
          .iphoneImg{
            width: 3.23rem;
            height: auto;
            position: absolute;
            top: 0;
            left: 4.5%;
          }
        }
      }
    }
    .footer{
      width: 7.50rem;
      height: 4.40rem;
      background: url('/activity/startPage/footerBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .footerTit{
        width: 5.93rem;
      }
      .sevenContent{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.45rem;
        p{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 0.5rem;
        }
        p:nth-child(1){
          img{
            width: 2.1rem;
            height: 2.1rem;
          }
          span{
            font-size: 0.24rem;
            color: #fff;
            margin-top: 0.1rem;
          }
        }
        p:nth-child(2){
          height: 2.1rem;
          justify-content: space-between;
          img{
            width: 2.7rem;
          }
        }
      }
    }
    .model_text {
      h2 {
        font-size: 16px;
        margin: 10px 0;
        color: #272c47;
        font-weight: bold;
      }
      span {
        color: #b1874c;
      }
      p {
        color: #727272;
        font-size: 12px;
        margin: 10px 0;
      }
    }
  }
</style>